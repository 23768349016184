<template>
  <div>
    <v-dialog
      v-model="showModal"
      max-width="400"
    >
      <v-card
        class="mx-auto"
      >
        <v-toolbar
          color="#37474F"
          dark
        >
          <v-toolbar-title>
            Confirm Device Structure
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <p>This action will confirm and create device group across your organization.</p>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green darken-1"
              text
              @click="showModal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="createConfirmed()"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card
      shaped
      color="#ECEFF1"
    >
      <br>
      <base-tree-view-without-actions
        :value="treeData"
        draggable
        droppable
        class="my-tree-view-style"
      />
      <v-col
        cols="12"
        class="text-right"
      >
        <v-btn
          class="mt-2"
          color="#FF3700"
          @click="create()"
        >
          create
        </v-btn>
      </v-col>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </div>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import { walkTreeData } from 'he-tree-vue';
  import Constants from 'src/constants';
  import global from 'src/mixins/global';

  export default {
    name: 'GroupDigiBoards',
    components: {
      'centre-spinner': spinner,
    },
    mixins: [global],
    data () {
      return {
        treeData: [],
        loading: false,
        showModal: false,
        deleteMessage: '',
      };
    },
    computed: {
      createdStructure () {
        return this.$store.getters['grouping/getCreatedStructure'];
      },
      digiBoards () {
        return this.$store.getters['digiboards/getDigiBoards'];
      },
      savedStructure () {
        return this.$store.getters['grouping/getSavedStructure'];
      },
    },
    async mounted () {
      this.loading = true;
      await this.$store.dispatch('digiboards/fetchDigiBoards');
      await this.$store.dispatch('grouping/fetchSavedStructure');
      this.createBoardTreeList();
      this.loading = false;
    },
    methods: {
      createBoardTreeList () {
        if (Object.keys(this.savedStructure).length <= 0) {
          const digiBoards = this.digiBoards;
          const structure = this.createdStructure;
          for (const digiboard of digiBoards) {
            structure[0].children.push({
              id: digiboard.external_id,
              text: `${digiboard.external_id} - ${digiboard.location}`,
            });
          }
          this.treeData = structure;
          return;
        }
          this.treeData = this.createdStructure;
          const availableBoards = [];
            walkTreeData(this.treeData, (node) => {
              if (node.id !== undefined) {
                availableBoards.push(node.id);
              }
            });
          const newBoards = this.digiBoards.filter(i => !availableBoards.includes(i.external_id));
          for (const newBoard of newBoards) {
            this.treeData[0].children.push({
              id: newBoard.external_id,
              text: `${newBoard.external_id} - ${newBoard.location}`,
            });
          }
      },
      create () {
        if (this.permissionCheck('create-group') === false) {
          this.$router.push({ name: 'Digi-Boards' });
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        if (this.treeData.length > 1 || this.treeData[0].text !== 'All Boards') {
          this.$store.dispatch('alert/onAlert', {
            message: 'All nodes should come under "All Boards".',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.showModal = true;
      },
      async createConfirmed () {
        this.loading = true;
        await this.$store.dispatch('grouping/groupDigiBoards', {
          group: this.treeData,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Device group created successfully',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$router.push({ name: 'Digi-Boards' });
        }).finally(
          this.showModal = false,
          this.loading = false,
        );
      },
    },
  };
</script>
<style scoped>
.my-tree-view-style {
  margin-left: 30px;
}
</style>
